import { 
  Alert, 
  AlertTitle, 
  VStack,
  Text,
} from '@chakra-ui/react';
import Lottie from 'lottie-react';
import React from 'react';
import ErrorLottie from "../../Components/Lotties/ErrorLottie.json";

export default function CheckoutCanceledPage() {
  return (
    <VStack py={10} alignSelf="center" alignItems="center" justifyContent="center">
        <Alert 
          textAlign="center"
          status="warning"
          px={[5, 10, 20, 30, 40]}
          py={12}
          justifyContent="center"
        > 
          <VStack alignSelf="center" spacing={4}>
            <AlertTitle 
              fontSize={[18, 20, 22, 24, 24]}
              color="perla.800"
            >
              <Text pb={2}>Attivazione abbonamento annullata!</Text>
            </AlertTitle>
          </VStack>
        </Alert>

        <Lottie 
            animationData={ErrorLottie} 
            style={{
                height: "300px",
            }}
        />;
    </VStack>
  );
}