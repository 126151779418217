import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  HStack,
  Button,
  Text
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

type AdminFoundModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function AdminFoundModal({
  isOpen,
  onClose,
}: AdminFoundModalProps) {

  const navigate = useNavigate();
  
  return !isOpen ? null : (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" trapFocus={true}>
      <ModalOverlay />
      <ModalContent minWidth="fit-content">
        <ModalCloseButton px={2} />
        <ModalBody mt={12} mx={4}>
          <Alert
            status="info"
            variant="subtle"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
            px={6}
          >
            <AlertIcon w="40px" h="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="xl">
              Administrator account
            </AlertTitle>
            <AlertDescription fontSize="lg">
              <Text>Ehi, tu sei un Amministratore e puoi accedere a tutti i video gratis.</Text> 
              <Text>Vuoi andare alla pagina dei tuoi video?</Text>
            </AlertDescription>
          </Alert>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button 
              backgroundColor="perla.500"
              color="white"
              onClick={() => {
                navigate("/video");
                onClose();
              }}
              w={40}
            >SI
            </Button>
            <Button 
              backgroundColor="gray"
              color="white"
              onClick={() => onClose()}
              w={40}
            >NO
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
