import React from 'react';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import ImportTabPanel from './Components/ImportTabPanel';
import UsersTabPanel from './Components/UsersTabPanel';

export function AdminPage() {

  return (
    <>
      <Tabs 
        variant='line'
        size='md' 
        align='start' 
        // isFitted 
        pt={2}
        px={4}
        colorScheme="perla"
      >
        <TabList gap={[4, 6, 8, 10]}>
          
          <Tab 
            fontSize={["16", "18", "20", "26", "30"]}
            fontWeight="bold"
            color="perla.600"
            _hover={{ color: 'orange.500' }}
            _selected={{ color: 'perla.500', borderColor: 'perla.500' }}
          >
            Utenti
          </Tab>
          <Tab 
            fontSize={["16", "18", "20", "26", "30"]}
            fontWeight="bold"
            color="perla.600"
            _hover={{ color: 'orange.500' }}
            _selected={{ color: 'perla.500', borderColor: 'perla.500' }}
          >
            Vimeo
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
              <UsersTabPanel/>
          </TabPanel>
          <TabPanel>
              <ImportTabPanel/>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}