import * as React from "react";
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Tfoot, Text, Checkbox, CheckboxProps } from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  Row
} from "@tanstack/react-table";

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  rowSelection: {},
  setRowSelection: React.Dispatch<React.SetStateAction<{}>>
  getRowId?: ((originalRow: Data, index: number, parent?: Row<Data> | undefined) => string) | undefined
};

export function DataTable<Data extends object>({
  data,
  columns,
  rowSelection,
  setRowSelection,
  getRowId
}: DataTableProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getSortedRowModel: getSortedRowModel(),
    getRowId: getRowId,
    state: {
      sorting,
      rowSelection
    }
  });

  return (
    <>
      <Table>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const meta: any = header.column.columnDef.meta;
                return (
                  <Th
                    key={`${header.id}-${header.index}`}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                    color="perla.200"
                    backgroundColor="perla.700"
                    fontWeight="bold"
                    fontSize={[12, 14, 14, 16, 18]}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}

                    <chakra.span pl="4">
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === "desc" ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={`${row.id}-${row.index}`}>
              {row.getVisibleCells().map((cell) => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const meta: any = cell.column.columnDef.meta;
                return (
                  <Td 
                    key={`${row.id}-${cell.id}`} 
                    isNumeric={meta?.isNumeric}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
        <Tfoot>
            <Tr>
            <Td>
              <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllPageRowsSelected(),
                  indeterminate: table.getIsSomePageRowsSelected(),
                  onChange: table.getToggleAllPageRowsSelectedHandler(),
                }}
              />
            </Td>
              <Td colSpan={20}>
                <Text>{table.getRowModel().rows.length} record trovati</Text>
                <Text>
                  {Object.keys(rowSelection).length} di{' '}
                  {table.getPreFilteredRowModel().rows.length} record selezionati
                </Text>
              </Td>
            </Tr>
          </Tfoot>
      </Table>

    </>
  );
}

export function IndeterminateCheckbox({
  indeterminate,
  ...rest
}: { indeterminate?: boolean } & CheckboxProps) {
  const ref = React.useRef<HTMLInputElement>(null!)

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <Checkbox
      colorScheme="teal"
      isIndeterminate={indeterminate}
      isChecked={rest.checked}
      ref={ref}
      {...rest}
    />
  )
}