import axios from "axios";
import { createContext, ReactNode, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Role, User } from "../ExternalTypes";
import authUtils from "../Utils/authUtils";

interface AuthContextType {
  isAuthenticated: boolean;
  token: string | Object;
  user: User;
  role: Role;
  onLogin: (username: string, password: string) => void;
  onLogout: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

type LocationProps = {
  state: {
    from: Location;
  };
};

export function AuthProvider ({ children }: {
    children: ReactNode;
}): JSX.Element {

  const location = useLocation() as unknown as LocationProps;
  const navigate = useNavigate();

  const handleLogin = async (email: string, password: string) => {
    console.log("email: " + email + " password: " + password);
    return await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/local?populate=role`, {
      identifier: email,
      password: password,
    })
    .then(async response => {
      authUtils.setToken(response.data.jwt);
      authUtils.setUser(response.data.user);
      authUtils.setRole(response.data.user.role);

      const origin = location.state?.from?.pathname || '/video';
      navigate(origin);
    });
  };

  const handleLogout = () => {
    authUtils.clearToken();
    authUtils.clearUser();
    authUtils.clearRole();

    const origin = location.state?.from?.pathname || '/';
    navigate(origin);
  };

  
  const accessToken = authUtils.getToken() as string;
  const user = authUtils.getUser() as User;
  const role = authUtils.getRole() as Role;

  const value = {
    isAuthenticated: accessToken != null && accessToken != "" ? true : false,
    token: accessToken,
    user: user,
    role: role,
    onLogin: handleLogin,
    onLogout: handleLogout,
  } as AuthContextType;

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
