import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Box, Button, Heading, Input, SimpleGrid, Stack, Text, Image, InputGroup, InputRightElement, FormControl, FormLabel, FormErrorMessage, Alert, AlertIcon, AlertDescription } from "@chakra-ui/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useRef, useState } from "react";
import * as Yup from "yup";
import Logo from '../Logo.webp';
import ReCAPTCHA from "react-google-recaptcha";

type LoginFormProps = {
  onSubmit: (values: LoginValues) => void;
  captchaRef: any;
};

export type LoginValues = {
  email: string;
  password: string;
};

export function LoginForm({ 
  onSubmit,
  captchaRef, 
}: LoginFormProps) {

  const schema = Yup.object().shape({
    email: Yup.string()
      .email("Devi inserire un indirizzo email valido")
      .required("Questo campo è obbligatorio."),
    password: Yup.string()
      .min(8, 'La password deve contenere almeno 8 caratteri.')
      .required("Questo campo è obbligatorio.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@:#\$%\^&\*\?\[\]])(?=.{8,})/,
        'La password deve contenere almeno 8 caratteri, uno maiuscolo, uno minuscolo, un numero e un carattere speciale tra i seguenti: !@:#$%^&*?[]'
      ),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordIsFilled, setShowPasswordIsFilledValue] = useState(false);
  const showPasswordHandleInput = (e: any) => {
    if (e.target.value !== "") setShowPasswordIsFilledValue(true);
    else setShowPasswordIsFilledValue(false);
  };
  
  return (
    <Box 
      alignSelf="center"
      w={["90%", "90%", "80%", "60%", "50%"]}
      borderWidth={2}
      borderRadius={10}
      boxShadow="md"
      bgColor="white"
    >
      <Formik<LoginValues>
        validationSchema={schema}
        initialValues={{
          email: "",
          password: ""
        }}
        onSubmit={(values) => {
          onSubmit(values);
          const token = captchaRef.current.getValue();
          captchaRef.current.reset();
        }}
      >
        {(props) => (
          <Form>
            <Stack 
              py={8} 
              px={[10, 10, 10, 10, 20]}
            >
              <Heading 
                pb={4} 
                alignSelf="center"
                color="perla.500"
                textAlign="center"
              >
                Entra nel mondo Perlalis
              </Heading>

              <Box alignSelf="center">
                <Image
                    src={Logo} 
                    alt="Perlalis"
                    height="auto"
                    width="100px"
                />
              </Box>

              <SimpleGrid 
                pb={4} 
                columns={[1, 1, 1]} 
                gap={4}
                fontSize={["18", "20", "22", "22", "24"]}
              >

                <Field 
                  id="email"
                  name="email"
                  type="email"
                >
                  {({ field, form }: any) => (
                    <FormControl isRequired>
                      <FormLabel
                        fontSize={["18", "20", "22", "22", "24"]}
                        fontWeight="bold"
                        color="perla.600"
                      >Email</FormLabel>
                      <Input 
                        {...field}
                        placeholder="Scrivi il tuo indirizzo email" 
                        fontSize={["18", "18", "20", "20", "22"]}
                        focusBorderColor="perla.600"
                        color="perla.800"
                        mb={3}
                      />
                      <ErrorMessage name="email" render={msg => (
                        <Stack>
                          <Alert status="error" min-height="28px">
                              <AlertIcon />
                              <AlertDescription fontSize="16px">{msg}</AlertDescription>
                          </Alert>
                        </Stack>
                      )}/>
                    </FormControl>
                  )}
                </Field>

                <Field 
                  id="password"
                  name="password"
                >
                  {({ field, form }: any) => (
                    <FormControl isRequired>
                      <FormLabel
                        fontSize={["18", "20", "22", "22", "24"]}
                        fontWeight="bold"
                        color="perla.600"
                      >Password</FormLabel>
                      <InputGroup size="md">
                        <Input 
                          {...field}
                          type={showPassword ? "text" : "password"}
                          placeholder="Scrivi la tua password" 
                          fontSize={["18", "18", "20", "20", "22"]}
                          focusBorderColor="perla.600"
                          color="perla.800"
                          mb={3}
                          onInput={showPasswordHandleInput}
                        />
                        {showPasswordIsFilled ? (
                        <InputRightElement
                          width="4.5rem"
                          marginRight={1}
                        >
                          <Button
                            h="2rem"
                            size="sm"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <ViewOffIcon w={6} h={6} color="red.500" />
                            ) : (
                              <ViewIcon w={6} h={6} color="perla.600" />
                            )}
                          </Button>
                        </InputRightElement>
                      ) : null}
                      </InputGroup>
                      <ErrorMessage name="password" render={msg => (
                        <Stack>
                          <Alert status="error" min-height="28px">
                              <AlertIcon />
                              <AlertDescription fontSize="16px">{msg}</AlertDescription>
                          </Alert>
                        </Stack>
                      )}/>
                    </FormControl>
                  )}
                </Field>
              </SimpleGrid>
                          
              {/* <ReCAPTCHA  
                sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY ?? ""}
                ref={captchaRef}
              /> */}

              <Stack py={6}>
                <Button 
                  type="submit" 
                  size="lg" 
                  py={8}
                  fontWeight="bold" 
                  backgroundColor="perla.600" 
                  color="white"
                  fontSize={["20", "22", "24", "26", "28"]}
                  _hover={{
                    backgroundColor: "perla.500",
                  }}
                  isLoading={props.isSubmitting}
                >ENTRA</Button>
              </Stack>

            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
