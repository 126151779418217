import { Navigate, Outlet, useLocation } from 'react-router-dom';
  
type ProtectedRouteProps = {
    isAllowed: boolean;
    redirectPath?: string;
    children?: JSX.Element;
}

export default function ProtectedRoute ({ 
    isAllowed,
    redirectPath = '/',
    children 
}: ProtectedRouteProps) {
  
    const location = useLocation();

    return (!isAllowed ?
        <Navigate to={redirectPath} replace state={{ from: location }}/> :
            children ? 
                children : 
                <Outlet />
    );
};