import { Spinner, Stack, useToast } from '@chakra-ui/react';
import axios from 'axios';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useAuth from '../../../Hooks/useAuth';
import { RiFoldersLine, RiVideoLine } from 'react-icons/ri';
import ImportBox from './ImportBox';
import { StrapiVideoResponse, VimeoVideo } from '../../../ExternalTypes';

type VimeoFolderType = {
  type: string;
  folder: {
    createdTime?: string;
    modifiedTime?: string;
    name: string;
    uri: string;
    metadata: {
      connections: {
        videos: {
          uri: string;
        }
      }
    }
  }
}

export default function ImportTabPanel() {

  const { token, user } = useAuth();

  const vimeoUserId = "153200526";
  const vimeoRootFolderId = "7703262";
  const vimeoHeaders = {
    'Authorization': `Bearer ${user.vimeoToken}`,
    'Content-Type': 'application/json',
  };
  const vimeoUrlDomain = "https://api.vimeo.com";
  const vimeoCategoriesQueryUrl = `${vimeoUrlDomain}/users/${vimeoUserId}/projects/${vimeoRootFolderId}/items`;
  const vimeoVideosQueryUrl = `https://api.vimeo.com/users/${vimeoUserId}/videos`;

  const strapiHeaders = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  
  const queryClient = useQueryClient();
  const toast = useToast();
  
  const { isLoading: isVimeoFoldersLoading, data: vimeoFolders } = useQuery(
    ["vimeo", "users", vimeoUserId, "projects", vimeoRootFolderId, "items"], 
    async () => {
      return await axios.get(vimeoCategoriesQueryUrl, { 
        headers: vimeoHeaders 
      })
        .then((response) => {
          return response.data;
        });
  });

  const { isLoading: isStrapiCategoriesLoading, data: strapiCategories } = useQuery(
    ["strapi", "categories"], 
    async () => {
      return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/categories`, 
        {
          headers: strapiHeaders
        })
        .then((response) => {
          return response.data;
        });
    }
  );

  const { isLoading: isVimeoVideosLoading, data: vimeoVideos } = useQuery(
    ["vimeo", "users", vimeoUserId, "videos"], 
    async () => {
      return await axios.get(vimeoVideosQueryUrl, { 
        headers: vimeoHeaders 
      })
        .then((response) => {
          return response.data;
        });
  });

  const { isLoading: isStrapiVideosLoading, data: strapiVideos } = useQuery(
    ["strapi", "videos"], 
    async () => {
      return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/videos`, 
        {
          headers: strapiHeaders
        })
        .then((response) => {
          return response.data;
        });
  });

  const getAllVimeoCategories = async (queryUrl: string, perPage?: number) => {

    const vimeoCategories: VimeoFolderType[] = await axios.get(
      perPage ? 
        `${queryUrl}?per_page=${perPage}` :
        `${queryUrl}`, 
    { 
      headers: vimeoHeaders 
    }).then(async r => {
      if (r.data.paging.next == null)
      { 
        return r.data.data;
      }

      const categories = (r.data.data as VimeoFolderType[]);
      categories.push(...(await getAllVimeoCategories(`${vimeoUrlDomain}${r.data.paging.next}`)));

      return categories;
    });
    
    return vimeoCategories;
  }

  const getAllVimeoVideos = async (queryUrl: string, perPage?: number) => {

    const vimeoVideos: VimeoVideo[] = await axios.get(
      perPage ? 
        `${queryUrl}?per_page=${perPage}` :
        `${queryUrl}`, 
    { 
      headers: vimeoHeaders 
    }).then(async r => {
      if (r.data.paging.next == null)
      { 
        return r.data.data;
      }

      const videos = (r.data.data as VimeoVideo[]);
      videos.push(...(await getAllVimeoVideos(`${vimeoUrlDomain}${r.data.paging.next}`)));

      return videos;
    });
    
    return vimeoVideos;
  }

  const { mutateAsync: importCategories, isLoading: isImportCategoriesLoading } = useMutation(
    async () => {
      const vimeoCategories = await getAllVimeoCategories(vimeoCategoriesQueryUrl, 50);

      return vimeoCategories.map(async (f: VimeoFolderType) => {

        const uriSplitted = f.folder.uri.split('/');

        const category = {
          Name: f.folder.name,
          Uri: f.folder.uri,
          VimeoVideosUri: f.folder.metadata.connections.videos.uri,
          VimeoFolderId: uriSplitted[uriSplitted.length - 1]
        }

        const categoryFound = await axios
          .get(`${process.env.REACT_APP_BACKEND_URL}/api/categories?filters[Name][$eq]=${category.Name}`, 
            {
              headers: strapiHeaders
            })
          .then(response => response.data);

        if ((categoryFound.data as []).length == 0) 
        {
          // Import the categories
          await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/categories`, 
          {
            data: category
          }, 
          {
            headers: strapiHeaders
          });
        }
      });
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(["strapi", "categories"], {
          refetchActive: true,
        });

        toast({
          title: 'IMPORTAZIONE DATI',
          description: `Importazione categorie effettuata con successo!`,
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
      },
      onError: (error, variables) => {
        queryClient.invalidateQueries(["strapi", "categories"], {
          refetchActive: true,
        });

        toast({
          title: 'IMPORTAZIONE DATI',
          description: `Importazione categorie fallita!`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
    }
  );

  const { mutateAsync: importVideos, isLoading: isImportVideosLoading } = useMutation(
    async () => {
      const vimeoVideos = await getAllVimeoVideos(vimeoVideosQueryUrl, 100);

      return vimeoVideos.map(async (f: VimeoVideo) => {

        const uriSplitted = f.uri.split('/');
        const folderUriSplitted = f.parent_folder.uri.split('/'); 

        const videosFound = await axios
          .get(`${process.env.REACT_APP_BACKEND_URL}/api/videos?filters[VimeoVideoId][$eq]=${uriSplitted[uriSplitted.length - 1]}`, 
            {
              headers: strapiHeaders
            })
          .then(response => response.data);

        if ((videosFound.data as StrapiVideoResponse[]).length == 0) 
        {
          const categoryFound = await axios
          .get(`${process.env.REACT_APP_BACKEND_URL}/api/categories?filters[VimeoFolderId][$eq]=${folderUriSplitted[folderUriSplitted.length - 1]}`, 
            {
              headers: strapiHeaders
            })
          .then(response => response.data);

          
          const tagsString: string[] = [];
          f.tags.map((t: any) => tagsString.push(t.canonical));

          const video = {
            Name: f.name,
            Description: f.description,
            Uri: f.uri,
            Link: f.link,
            Duration: f.duration,
            VimeoVideoId: uriSplitted[uriSplitted.length - 1],
            VimeoFolderId: folderUriSplitted[folderUriSplitted.length - 1],
            Tags: tagsString.join(','),
            Category: categoryFound.data[0]?.id,
            VimeoPictureBaseLink: f.pictures.base_link,
            VimeoPictureUri: f.pictures.uri
          };

          // Import the videos
          await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/videos`, 
          {
            data: video
          }, 
          {
            headers: strapiHeaders
          });
        }
      });
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(["strapi", "videos"], {
          refetchActive: true,
        });

        toast({
          title: 'IMPORTAZIONE DATI',
          description: `Importazione video effettuata con successo!`,
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
      },
      onError: (error, variables) => {
        queryClient.invalidateQueries(["strapi", "videos"], {
          refetchActive: true,
        });

        toast({
          title: 'IMPORTAZIONE DATI',
          description: `Importazione video fallita!`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      }
    }
  );

  return (
    <Stack minH={200} spacing={4} my={6}>

        {/* CATEGORIE */}
        {isVimeoFoldersLoading || isStrapiCategoriesLoading ? 
          <Spinner 
            size="lg" 
            alignSelf="center"
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='perla.500'
          /> :
          <ImportBox
            title='CATEGORIE'
            perlalisItems={strapiCategories}
            vimeoItems={vimeoFolders}
            perlalisIconType={RiFoldersLine}
            vimeoIconType={RiFoldersLine}
            handleImport={async () => await importCategories()}
            isImportLoading={isImportCategoriesLoading}
          />
        }

        {/* VIDEO */}
        {isVimeoVideosLoading || isStrapiVideosLoading || isStrapiCategoriesLoading ? 
          <Spinner 
            size="lg" 
            alignSelf="center"
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='perla.500'
          /> : strapiCategories.meta.pagination.total > 0 ?
          <ImportBox
            title='VIDEO'
            perlalisItems={strapiVideos}
            vimeoItems={vimeoVideos}
            perlalisIconType={RiVideoLine}
            vimeoIconType={RiVideoLine}
            handleImport={async () => await importVideos()}
            isImportLoading={isImportVideosLoading}
          /> : null
        }
    </Stack>
  );
}