import React, { useRef } from 'react';
import { Stack, VStack } from '@chakra-ui/react';
import { useMutation } from 'react-query';
import { LoginForm, LoginValues } from '../Components/LoginForm';
import useAuth from '../Hooks/useAuth';
import { useToast, Text } from '@chakra-ui/react';
import { AxiosError, AxiosResponse } from 'axios';

export default function LoginPage() {

  const captchaRef = useRef(null);
  const { onLogin } = useAuth();
  const toast = useToast({
    position: 'top',
    containerStyle: {
      minWidth: '480px',
      maxWidth: '100%',
    },
  });

  const { mutateAsync: loginMutate } = useMutation(
    async (values: LoginValues) => {
      return onLogin(values.email, values.password);
    },
    {
      onSuccess: (data, variables) => {
        toast({
          title: 'LOGIN',
          description: `${variables.email} ha effettuato il login con successo!`,
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      },
      onError: (error: AxiosError, variables) => {
        var response: AxiosResponse<any,any> | undefined = error.response;
        toast({
          title: 'LOGIN',
          description: 
            <VStack pt={4} spacing={1} alignItems="baseline">
              <Text>{error.message}</Text>
              <Text fontStyle="italic">{response?.data.error.name}: {response?.data.error.message}</Text>
            </VStack>,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      }
    }
  );

  return (
    <Stack py={10}>
      <LoginForm 
        onSubmit={loginMutate} 
        captchaRef={captchaRef}
      />
    </Stack>
  );
}