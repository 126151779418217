import { 
    Text, 
    Box, 
    IconButton, 
    Menu, 
    MenuButton, 
    MenuItem, 
    MenuList, 
    Link
} from "@chakra-ui/react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { MenuVoice } from "../Header";

type HamburgerMenuProps = {
    items: MenuVoice[];
}

export default function HamburgerMenu({
    items,
}: HamburgerMenuProps) {
    
    return (
        <Box 
            pr={4} 
            zIndex={1000}
        > 
            <Menu isLazy>
            {({ isOpen }) => (
                <>
                <MenuButton
                    isActive={isOpen}
                    as={IconButton}
                    boxSize={50}
                    aria-label='Options'
                    icon={isOpen ? <MdClose size={30}/> : <GiHamburgerMenu size={30}/>}
                    variant='outline'
                    bgColor="perla.600"
                    color="white"
                    borderColor="perla.500"
                    borderWidth={0}
                    _hover={{ 
                    bg: 'perla.700',
                    color: 'white',
                    borderWidth: '2px'
                    }}
                    _expanded={{ 
                    bg: 'perla.600',
                    borderColor: 'perla.500'
                    }}
                />                    
                <MenuList 
                    bgColor="perla.600"
                    color="white"
                    borderColor="perla.500"
                    borderWidth={2}
                    textAlign="right"
                    zIndex={1000}
                >
                    {items.map(i => {
                    return (
                        <MenuItem 
                            display="block"
                            textAlign="end"
                            py={2}
                            fontFamily="BabyEliot"
                            fontSize={[16, 18, 20, 20, 22]}
                            pr={2}
                            key={i.id} 
                            backgroundColor="perla.600"
                            _hover={{ 
                                bg: 'perla.700',
                                fontWeight: 'bold'
                            }}
                            _focus={{ bg: 'perla.600' }}
                            // onClick={() => navigate(i.to)}
                        >
                            <Link 
                                as={NavLink}
                                to={i.to} 
                                _hover={{
                                    color: "white",
                                    fontWeight: "bold"
                                }}
                                _activeLink={{
                                    color: "perla.400"
                                }}
                            >
                                <Text textAlign="center">{i.name.toUpperCase()}</Text>
                            </Link>
                        </MenuItem>
                    );
                    })}              
                </MenuList>
                </>
            )}
            </Menu>
        </Box>
    )
}