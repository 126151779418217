export enum OrderType {
        Subscription = "Subscription",
        OneTime = "OneTime"
}
  
export type User = {
    id: string;
    username: string;
    email: string;
    name: string;
    password?: string;
    confirmed: boolean;
    blocked: boolean;
    role: Role;
    vimeoToken: string;
    createdAt: string;
    updatedAt: string;
    Order: Order;
}

export type StrapiUserResponse = {
    id: string;
    attributes: {
        username: string;
        email: string;
        name: string;
        role: Role;
        vimeoToken: string;
        createdAt: string;
        updatedAt: string;
    }
}

export type Role = {
    id: string;
    description: string;
    name: string;
    type: string;
    createdAt: string;
    updatedAt: string;
}
  
export type Order = {
    id: string;
    orderId: string;
    Type: OrderType;
    CustomerId: string;
    SubscriptionId: string;
    ProductId: string;
    ProductName: string;
    MaxVideos: number;
    createAt: string;
    publishedAt: string;
    updatedAt: string;
}

export type StrapiOrderResponse = {
    id: string;
    attributes: {
        orderId: string;
        User: {
            data: StrapiUserResponse;
        };
        Type: OrderType;
        CustomerId: string;
        SubscriptionId: string;
        ProductId: string;
        MaxVideos: number;
        createAt: string;
        publishedAt: string;
        updatedAt: string;
    }
}

export type Video = {
    id: string;
    Name: string;
    Description: string;
    Price: number;
    Link: string;
    Duration: number;
    Category: {
        data: any;
    }
    Tags: string;
    VimeoVideoId: string;
    VimeoFolderId: string;
    VimeoPictureBaseLink: string;
    VimeoPictureUri: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
}

export type StrapiVideoResponse = {
    id: string;
    attributes: {
        Name: string;
        Description: string;
        Price: number;
        Link: string;
        Duration: number;
        Category: {
            data: any;
        }
        Tags: string;
        VimeoVideoId: string;
        VimeoFolderId: string;
        VimeoPictureBaseLink: string;
        VimeoPictureUri: string;
        createdAt: string;
        updatedAt: string;
        publishedAt: string;
    }
}

export type StrapiCategoryResponse = {
    id: string;
    attributes: {
        Name: string;
        VimeoVideosUri: string;
        VimeoFolderId: string;
        Uri: string;
        Videos: {
            data: Video[];
        }
        createdAt: string;
        updatedAt: string;
    }
}

export type VideoEnabled = {
    id: string;
    attributes: {
        User: {
            data: StrapiUserResponse;
        };
        Video: {
            data: StrapiVideoResponse;
        }
    }
}

export type VimeoVideo = {
    uri: string;
    name: string;
    description: string;
    link: string;
    price: number;
    duration: number;
    pictures: {
        uri: string;
        base_link: string;
    }
    parent_folder: {
      name: string;
      uri: string;
    }
    tags: [];
  }

export enum StripeSubscriptionStatus {
    Incomplete = "incomplete",
    IncompleteExpired = "incomplete_expired",
    Trialing = "trialing",
    Active = "active",
    PastDue = "past_due",
    Canceled = "canceled", 
    Unpaid = "unpaid"
}

export type StripeSubscription = {
    id: string,
    object: any,
    application?: any,
    application_fee_percent?: any,
    automatic_tax: {
        enabled: boolean
    },
    billing_cycle_anchor?: number,
    billing_thresholds: any,
    cancel_at?: number,
    cancel_at_period_end?: any,
    canceled_at?: number,
    collection_method: string,
    created: number,
    currency: string,
    current_period_end: number,
    current_period_start: number,
    customer: string,
    days_until_due?: any,
    default_payment_method?: any,
    default_source: string,
    default_tax_rates: [],
    description?: any,
    discount?: any,
    ended_at: number,
    items: {
        object: string,
        data: [
            {
                id: string,
                object: string,
                billing_thresholds?: any,
                created: number,
                metadata: {},
                price: {
                    id: string,
                    object: string,
                    active: boolean,
                    billing_scheme: string,
                    created: number,
                    currency: string,
                    custom_unit_amount?: any,
                    livemode: boolean,
                    lookup_key: string,
                    metadata: {},
                    nickname?: any,
                    product: string,
                    recurring: {
                        aggregate_usage?: any,
                        interval: string,
                        interval_count: number,
                        usage_type: string
                    },
                    tax_behavior: string,
                    tiers_mode?: any,
                    transform_quantity?: any,
                    type: string,
                    unit_amount: number,
                    unit_amount_decimal: string
                },
                quantity: number,
                subscription: string,
                tax_rates: []
            }
        ],
        has_more: boolean,
        total_count?: number,
        url: string
    },
    latest_invoice: string,
    livemode: boolean,
    metadata: {},
    next_pending_invoice_item_invoice?: any,
    on_behalf_of?: any,
    pause_collection?: any,
    payment_settings: {
        payment_method_options?: any,
        payment_method_types?: any,
        save_default_payment_method: string
    },
    pending_invoice_item_interval?: any,
    pending_setup_intent?: any,
    pending_update?: any,
    plan?: {
        id: string,
        object: string,
        active: true,
        aggregate_usage?: any,
        amount: number,
        amount_decimal: string,
        billing_scheme: string,
        created: number,
        currency: string,
        interval: string,
        interval_count: number,
        livemode: boolean,
        metadata: {},
        nickname?: any,
        product: string,
        tiers_mode?: any,
        transform_usage?: any,
        trial_period_days?: any,
        usage_type: string
    },
    quantity?: number,
    schedule?: any,
    start_date: number,
    status: string,
    test_clock: string,
    transfer_data?: any,
    trial_end?: any,
    trial_start?: any
}