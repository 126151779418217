import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  HStack,
  Button,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

type OrderFoundModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function OrderFoundModal({
  isOpen,
  onClose,
}: OrderFoundModalProps) {

  const navigate = useNavigate();
  
  return !isOpen ? null : (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" trapFocus={true}>
      <ModalOverlay />
      <ModalContent minWidth="fit-content">
        <ModalCloseButton px={2} />
        <ModalBody mt={12} mx={4}>
          <Alert
            status="info"
            variant="subtle"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
            px={6}
          >
            <AlertIcon w="40px" h="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="xl">
              Abbonamento trovato!
            </AlertTitle>
            <AlertDescription fontSize="lg">
              Ehi, tu hai già sottoscritto un abbonamento. Vuoi andare alla pagina dei tuoi video?
            </AlertDescription>
          </Alert>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button 
              backgroundColor="perla.500"
              color="white"
              onClick={() => {
                navigate("/video");
                onClose();
              }}
              w={40}
            >SI
            </Button>
            <Button 
              backgroundColor="gray"
              color="white"
              onClick={() => onClose()}
              w={40}
            >NO
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
