import { Box, Heading, VStack } from '@chakra-ui/react';
import React from 'react';
import Iframe from 'react-iframe';

export default function CookiePolicyPage() {
  
  return (
    <VStack 
      w="100%"
      py={4} 
      alignSelf="center"
    >
      <Heading 
        color="perla.600"
        fontSize={[30, 34, 34, 36, 40]}
      >
        Cookie Policy
      </Heading>

      <Box
        w={["100%", "90%", "80%", "70%", "60%"]}
        borderWidth={1}
        borderRadius={10}
        boxShadow="md"
        bgColor="whitesmoke"
        px={4}
        pb={10}
      >
        <Iframe 
          url="https://app.legalblink.it/api/documents/63736a37d769cf001bfebe1e/cookie-policy-it"
          width="100%"
          height="800px"
          frameBorder={0}
          scrolling="yes"
        />
      </Box>
    </VStack>
  );
}