import { Heading, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';

export default function CosaFacciamoPage() {
  return (
    <VStack w={["92%", "92%", "80%", "70%", "70%"]} px={10} py={10} alignSelf="center">
      <Heading 
        color="perla.500"
        fontFamily="BabyEliot"
        fontWeight="bold"
        fontSize={["24", "26", "30", "32", "36"]}
        alignSelf="flex-start"
      >VALORI</Heading>
      <Text
        color="gray.600"
        fontFamily="Georgia"
        fontSize={["20", "22", "26", "28", "28"]}
        alignSelf="flex-start"
        pb={8}
      >
        La lingua dei segni è considerata patrimonio linguistico e culturale da veicolare in quanto strumento fondamentale per la crescita sociale, culturale e umana delle persone sorde. 
        Trattandosi di una lingua di minoranza, crediamo fortemente che questa debba essere sostenuta e valorizzata.
      </Text>
      <Heading 
        color="perla.500"
        fontFamily="BabyEliot"
        fontWeight="bold"
        fontSize={["24", "26", "30", "32", "36"]}
        alignSelf="flex-start"
      >VISIONE</Heading>
      <Text
        color="gray.600"
        fontFamily="Georgia"
        fontSize={["20", "22", "26", "28", "28"]}
        alignSelf="flex-start"
        pb={8}
      >
        L’obiettivo di PerlaLis è quello di diffondere la lingua dei segni italiana attraverso la ricerca di un lessico che può essere utilizzato nella vita quotidiana attraverso semplici conversazioni. 
        L’intento è quello di andare anche oltre le proprietà linguistiche e di far conoscere le caratteristiche culturali della comunità sorda.
      </Text>
      <Heading 
        color="perla.500"
        fontFamily="BabyEliot"
        fontWeight="bold"
        fontSize={["24", "26", "30", "32", "36"]}
        alignSelf="flex-start"
      >MISSIONE</Heading>
      <Text
        color="gray.600"
        fontFamily="Georgia"
        fontSize={["20", "22", "26", "28", "28"]}
        alignSelf="flex-start"
        pb={8}
      >
        La nostra missione è quella di preservare la nostra lingua e cultura, di far valere i nostri diritti umani e civili, e di sensibilizzare la società combattendo contro i pregiudizi linguistici e culturali.
      </Text>
    </VStack>
  );
}