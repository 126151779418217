import {
    Route,
    Routes,
    Navigate,
  } from "react-router-dom";
import ProtectedRoute from "./Components/ProtectedRoute";
import useAuth from "./Hooks/useAuth";
import { AdminPage } from "./Pages/Admin/AdminPage";
import CheckoutCanceledPage from "./Pages/Checkout/CheckoutCanceledPage";
import CheckoutSuccessPage from "./Pages/Checkout/CheckoutSuccessPage";
import ChiSiamoPage from "./Pages/Chi siamo/ChiSiamoPage";
import CondizioniUsoPage from "./Pages/CondizioniUsoPage";
import CondizioniUso from "./Pages/CondizioniUsoPage";
import CookiePolicyPage from "./Pages/CookiePolicyPage";
import CosaFacciamoPage from "./Pages/CosaFacciamoPage";
import EmailConfirmationPage from "./Pages/EmailConfirmationPage";
import HomePage from "./Pages/Home/HomePage";
import InformativaPrivacyPage from "./Pages/InformativaPrivacyPage";
import LoginPage from "./Pages/LoginPage";
import { NoMatchPage } from "./Pages/NoMatchPage";
import RegisterPage from "./Pages/RegisterPage";
import VideoPage from "./Pages/Video/VideoPage";

export default function AppRoutes() {

    const { 
        isAuthenticated,
        role
    } = useAuth();

    return (
        <Routes>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/register" element={isAuthenticated ? <Navigate to="/video"/> : <RegisterPage/>}/>
            <Route path="/login" element={isAuthenticated ? <Navigate to="/video"/> : <LoginPage/>}/>
            <Route path="/chi-siamo" element={<ChiSiamoPage/>}/>
            <Route path="/cosa-facciamo" element={<CosaFacciamoPage/>}/>
            <Route path="/condizioni-uso" element={<CondizioniUsoPage/>}/>
            <Route path="/informativa-privacy" element={<InformativaPrivacyPage/>}/>
            <Route path="/cookie-policy" element={<CookiePolicyPage/>}/>
            <Route path="/email-confirmation" element={<EmailConfirmationPage/>}/>

            <Route element={<ProtectedRoute isAllowed={isAuthenticated}/>}>
                <Route path="/video" element={<VideoPage />}/>
                <Route path="/checkoutSuccess/:sessionId" element={<CheckoutSuccessPage />}/>
                <Route path="/checkoutCanceled" element={<CheckoutCanceledPage />}/>
            </Route>

            <Route path="/admin" element={
                <ProtectedRoute isAllowed={isAuthenticated && role.name == 'Administrator'}>
                    <AdminPage />
                </ProtectedRoute>
            }
            />
            <Route path="*" element={<NoMatchPage />} />
        </Routes>
    );
}