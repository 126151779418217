import { Box, Stack, Text, Image } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import ImgLingua from '../../../Images/lingua slider.jpg';
import ImgAmici from '../../../Images/amici slider.jpg';
import ImgFamiglia from '../../../Images/famiglia slider.jpg';
import ImgCorsi from '../../../Images/corsi slider.jpg';
import ImgBambini from '../../../Images/bambini slider.jpg';
import ImgInterprete from '../../../Images/interprete.webp';
// CSS
import 'swiper/css';
import "swiper/css/pagination";
import "../../../Styles/Slider.css"

export default function Slider() {

    return (
        <Stack 
            maxH={560}
            color="white" 
            fontFamily="BabyEliot"
            fontSize={["30", "40", "50", "70", "80"]}
        >
            <Swiper
                modules={[Pagination, Autoplay]}
                slidesPerView={1}
                pagination={{ clickable: true }}
                loop={true}
                centeredSlides={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
            >
                <SwiperSlide> 
                    <Stack>
                        <Box h="100%" w="100%" bg="gray" position="absolute" opacity={0.7}/>
                        <Text 
                        position="absolute" 
                        textAlign="center"
                        top={[2, 3, 20, 20, 40]}
                        bottom={0}
                        left={0}
                        right={0}
                        >Imparare una lingua affascinante</Text>

                        <Image
                            src={ImgLingua} 
                            alt="Lingua dei segni"
                            height="100%"
                            width="100%"
                            minHeight={320}
                            objectFit="cover"
                            style={{
                            marginTop: 0
                            }}
                        />
                    </Stack> 
                </SwiperSlide>
                <SwiperSlide>
                    <Box h="100%" w="100%" bg="gray" position="absolute" opacity={0.7}/>
                    <Text 
                    position="absolute" 
                    textAlign="center"
                    top={[2, 3, 20, 20, 40]}
                    bottom={0}
                    left={0}
                    right={0}
                    >Trovare nuovi amici</Text>
                    <Image
                        src={ImgAmici} 
                        alt="Amici"
                        height="auto"
                        width="100%"
                        minHeight={320}
                        objectFit="cover"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <Box h="100%" w="100%" bg="gray" position="absolute" opacity={0.7}/>
                    <Text 
                    position="absolute" 
                    textAlign="center"
                    top={[2, 3, 20, 20, 40]}
                    bottom={0}
                    left={0}
                    right={0}
                    >Migliorare la comunicazione in famiglia</Text>            
                    <Image
                        src={ImgFamiglia} 
                        alt="Famiglia"
                        height="auto"
                        width="100%"
                        minHeight={320}
                        objectFit="cover"
                        style={{
                        marginTop: 0
                        }}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <Box h="100%" w="100%" bg="gray" position="absolute" opacity={0.7}/>
                    <Text 
                    position="absolute" 
                    textAlign="center"
                    top={[2, 3, 20, 20, 40]}
                    bottom={0}
                    left={0}
                    right={0}
                    >Sostenere l'apprendimento dei bambini sordi</Text>
                    <Image
                        src={ImgBambini} 
                        alt="Bambini"
                        height="auto"
                        width="100%"
                        minHeight={320}
                        objectFit="cover"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <Box h="100%" w="100%" bg="gray" position="absolute" opacity={0.7}/>
                    <Text 
                    position="absolute" 
                    textAlign="center"
                    top={[2, 3, 20, 20, 40]}
                    bottom={0}
                    left={0}
                    right={0}
                    >Diventare un interprete migliore</Text>            
                    <Image
                        src={ImgInterprete} 
                        alt="Interprete"
                        height="auto"
                        width="100%"
                        minHeight={320}
                        objectFit="cover"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <Box h="100%" w="100%" bg="gray" position="absolute" opacity={0.7}/>
                    <Text 
                    position="absolute" 
                    textAlign="center"
                    top={[2, 3, 20, 20, 40]}
                    bottom={0}
                    left={0}
                    right={0}
                    >Usare i nostri video per i tuoi corsi</Text>
                    <Image
                        src={ImgCorsi} 
                        alt="Corsi"
                        height="auto"
                        width="100%"
                        minHeight={320}
                        objectFit="cover"
                    />
                </SwiperSlide>
            </Swiper>
        </Stack>
    );
}