import { Stack, Image } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper';
import ImgAngela from './Images/Angela.jpg';
import ImgBiagio from './Images/Biagio.jpg';
import ImgFrancesca from './Images/Francesca.jpg';
import ImgGiorgia from './Images/Giorgia.jpg';
import ImgGiuseppe from './Images/Giuseppe.jpg';
import ImgJessica from './Images/Jessica.jpg';
import ImgLillo from './Images/Lillo.jpg';
import ImgMarco from './Images/Marco.jpg';
import ImgMariona from './Images/Mariona.jpg';
import ImgWalter from './Images/Walter.jpg';

// CSS
import 'swiper/css';
import "swiper/css/pagination";
import "../../Styles/Slider.css"

const ImgList = [
    ImgLillo,
    ImgAngela,
    ImgGiorgia,
    ImgBiagio,
    ImgFrancesca,
    ImgGiuseppe,
    ImgJessica,
    ImgMarco,
    ImgMariona,
    ImgWalter
]

export default function ChiSiamoSlider() {

    return (
        <Stack bgColor="white">
            <Swiper
                modules={[Pagination, EffectCoverflow]}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={2}
                loop={true}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                  }}
            >
                {ImgList.map((img) => {
                    return (
                        <SwiperSlide key={`slide-${img.toLowerCase()}`}> 
                            <Image
                                src={img} 
                                height="auto"
                                width="100%"
                                minHeight={180}
                                objectFit="cover"
                            />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </Stack>
    );
}