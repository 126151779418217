import { 
  Alert, 
  AlertDescription, 
  AlertTitle, 
  VStack,
  Text,
  Link
} from '@chakra-ui/react';
import Lottie from 'lottie-react';
import SuccessLottie from '../Components/Lotties/SuccessLottie.json';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../Hooks/useAuth';

export default function EmailConfirmationPage() {

  const navigate = useNavigate();
  const { token, user } = useAuth();

  return (
    <VStack py={10} alignSelf="center" alignItems="center" justifyContent="center">
        <Alert 
          textAlign="center"
          status="success"
          backgroundColor="perla.200"
          px={[5, 10, 20, 30, 40]}
          py={12}
          mb={-12}
          justifyContent="center"
        > 
          <VStack alignSelf="center" spacing={4}>
            <AlertTitle 
              fontSize={[18, 20, 22, 24, 24]}
              color="perla.800"
            >
              <Text fontSize={[24, 26, 28, 30, 30]} pb={4}>Congratulazioni!</Text>
              <Text>Il tuo account è stato attivato correttamente.</Text>
            </AlertTitle>
            <AlertDescription fontSize={[16, 18, 20, 22, 22]}>
              Vai alla pagina di <Link fontWeight="bold" color="perla.700" onClick={() => navigate("/login")}><Text as='u'>login</Text></Link> e utilizza le tue credenziali per entrare nel mondo Perlalis!
            </AlertDescription>
          </VStack>
        </Alert>

        <Lottie 
            animationData={SuccessLottie} 
            style={{
                height: "320px",
            }}
        />;
    </VStack>
  );
}