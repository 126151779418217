import { Box, Button, HStack, Icon, Tag, VStack, Text, Progress } from "@chakra-ui/react";
import { FaExchangeAlt } from "react-icons/fa";
import { IconType } from "react-icons/lib";
import Lottie from "lottie-react";
import ImportArrow from "../../../Components/Lotties/ImportArrow.json";

type ImportBoxProps = {
    title: string;
    perlalisItems: any[any];
    perlalisIconType: IconType;
    vimeoItems: any[any];
    vimeoIconType: IconType;
    handleImport: () => void;
    isImportLoading: boolean;
}

export default function ImportBox({
    title,
    perlalisItems,
    perlalisIconType,
    vimeoItems,
    vimeoIconType,
    handleImport,
    isImportLoading
}: ImportBoxProps) {

    return (
        <Box 
            w="80%"            
            alignSelf="center" 
            pb={6}
        >
        <Tag 
            w="100%"
            backgroundColor="perla.700" 
            justifyContent="center" 
            borderRadius={14}
            mt={0}
        >
            <Text 
                fontSize={[22, 24, 26, 28, 28]} 
                py={1} 
                color="white">{title.toUpperCase()}
            </Text>
        </Tag>  
        
        <HStack 
            py={2} 
            justifyContent="center"
            spacing={[10, 10, 20, 40, 60]}
        >
            <VStack w="30%">
                <Text 
                    color="gray.600" 
                    fontWeight="bold" 
                    fontSize={[22, 24, 26, 28, 28]}
                >Vimeo</Text>
                <Icon as={vimeoIconType} w={30} h={30}/>
                <Tag py={2} backgroundColor="perla.500" justifyContent="center" borderRadius={30}>
                    <Text 
                    px={2} 
                    color="white"
                    fontSize={[24, 26, 28, 30, 30]}
                    >
                    {vimeoItems?.total ?? 0}
                    </Text>
                </Tag>
            </VStack>

            <VStack spacing={4} w="40%">
                {/* <Lottie 
                    animationData={ImportArrow} 
                    style={{
                        height: 50,
                    }}
                />; */}
                {isImportLoading ? <Progress size='md' w="100%" colorScheme="orange" isIndeterminate/> : null}
                <Button 
                    onClick={handleImport}
                    bgColor="perla.500"
                    _hover={{
                    backgroundColor: "orange.500"
                    }}
                    isDisabled={isImportLoading || (vimeoItems.total == perlalisItems.meta.pagination.total)}
                >
                    <Text color="white" fontSize={[16, 18, 20, 24, 24]}>
                        IMPORTA
                    </Text>
                </Button>
            </VStack>


            <VStack w="30%">
                <Text 
                    color="gray.600" 
                    fontWeight="bold" 
                    fontSize={[22, 24, 26, 28, 28]}
                >Perlalis</Text>
                <Icon as={perlalisIconType} w={30} h={30}/>
                <Tag py={2} backgroundColor="perla.500" justifyContent="center" borderRadius={30}>
                    <Text 
                    px={2} 
                    color="white"
                    fontSize={[24, 26, 28, 30, 30]}
                    >
                        {perlalisItems ? perlalisItems.meta.pagination.total : 0}
                    </Text>
                </Tag>
            </VStack>
        </HStack>
        </Box>
    );
}