import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";
import React from "react";

type UserDelDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  cancelRef: React.MutableRefObject<any>;
};

export function UserDelDialog({
  isOpen,
  onClose,
  onDelete,
  cancelRef
}: UserDelDialogProps) {

  return !isOpen ? null : (
    <AlertDialog 
      isOpen={isOpen} 
      onClose={onClose} 
      size="xl" 
      leastDestructiveRef={cancelRef}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent
          minWidth="fit-content" 
          bgColor="white"
        >
          <AlertDialogHeader 
            fontSize='2xl' 
            fontWeight='bold'
            color="perla.500"
          >
              Rimuovi cliente
          </AlertDialogHeader>

          <AlertDialogBody>
            Sei sicuro? Questa azione rimuoverà gli utenti selezionati e tutti i dati ad esso associati (ordini, video abilitati...ecc).
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button 
              ref={cancelRef} 
              onClick={onClose}
            >
              Annulla
            </Button>
            <Button 
              colorScheme='red' 
              onClick={onDelete} 
              ml={3}
            >
              Rimuovi
            </Button>
          </AlertDialogFooter>

        </AlertDialogContent>

      </AlertDialogOverlay>
    </AlertDialog>
  );
}
