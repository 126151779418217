import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Stack } from '@chakra-ui/react';
import AppRoutes from './AppRoutes';

export default function App() {
  
  return ( 
      <Stack>
          <Header/>
          <AppRoutes/>
          <Footer/>
      </Stack>
  );
} 