import { HStack, IconButton, IconButtonProps } from "@chakra-ui/react";
import { FaInstagram, FaVimeo } from "react-icons/fa";
import { MdOutlineFacebook } from "react-icons/md";

export default function SocialBar({
    fontSize = 36
}: Pick<IconButtonProps,"fontSize">) {

    return (
        <HStack spacing={4} display="block" minWidth="fit-content">
            <a href="https://www.facebook.com/Perlalis-110035398265915" target="_blank" rel="noreferrer">
            <IconButton
                variant="outline"
                color="white"
                aria-label='Facebook'
                fontSize={fontSize}
                icon={<MdOutlineFacebook/>}
                borderWidth={0}
                _hover={{ 
                    bg: 'perla.800',
                    color: 'perla.500',
                }}
            />
            </a>
            <a href="https://instagram.com/perlalis_?utm_medium=copy_link" target="_blank" rel="noreferrer">
            <IconButton
                variant="outline"
                color="white"
                aria-label='Instagram'
                fontSize={fontSize}
                icon={<FaInstagram/>}
                borderWidth={0}
                _hover={{ 
                    bg: 'perla.800',
                    color: 'perla.500',
                }}
            />
            </a>
        </HStack>
    )
}