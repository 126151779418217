import { 
  Alert, 
  AlertDescription, 
  AlertTitle, 
  Spinner, 
  Stack, 
  VStack,
  Text,
  Link
} from '@chakra-ui/react';
import axios from 'axios';
import Lottie from 'lottie-react';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import SuccessLottie from "../../Components/Lotties/SuccessLottie.json";
import { StrapiOrderResponse, StripeSubscription } from '../../ExternalTypes';
import useAuth from '../../Hooks/useAuth';
import { NoMatchPage } from '../NoMatchPage';

export default function CheckoutSuccessPage() {

  const navigate = useNavigate();
  const { sessionId } = useParams();
  const { token, user } = useAuth();
  const strapiHeaders = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  const qs = require('qs');
  
  const { isLoading: isOrderLoading, data: order } = useQuery(
    ["orders", user.id], 
    async () => {
      const query = qs.stringify({
        sort: ['createdAt:asc'],
        filters: {
          User: {
            id: {
              $eq: user.id,
            }
          },
        },
        populate: '*'
      }, {
        encodeValuesOnly: true, // prettify URL
      });

      return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/orders?${query}`, 
        {
          headers: strapiHeaders
        })
        .then((response) => {
          return response.data.data[0] ? response.data.data[0] as StrapiOrderResponse : null;
        });
    }
  );

  const { isLoading: isSubscriptionLoading, data: subscription, isError: isSubcriptionError } = useQuery(
  ["orders", "getSubscription", order?.attributes.SubscriptionId], 
    async () => {
      return (!order || order == null) ? null : 
          await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/orders/getSubscription`,
          {
            data: { subscriptionId: order?.attributes.SubscriptionId }
          }, 
          {
            headers: strapiHeaders
          },
          )
          .then((response) => {
            return response.data as StripeSubscription;
          });
  });

  if (!sessionId || isSubcriptionError)
    return <NoMatchPage/>;

  return (isOrderLoading || isSubscriptionLoading) ?
  (
    <Stack alignItems="center" justifyContent="center">
      <Spinner size="md"/>
    </Stack>
  ) :
  (
    <VStack py={10} alignSelf="center" alignItems="center" justifyContent="center">
        <Alert 
          textAlign="center"
          status="success"
          backgroundColor="perla.200"
          px={[5, 10, 20, 30, 40]}
          py={12}
          mb={-12}
          justifyContent="center"
        > 
          <VStack alignSelf="center" spacing={4}>
            <AlertTitle 
              fontSize={[18, 20, 22, 24, 24]}
              color="perla.800"
            >
              <Text fontSize={[24, 26, 28, 30, 30]} pb={4}>Congratulazioni!</Text>
              <Text>Hai attivato il tuo abbonamento.</Text>
            </AlertTitle>
            <AlertDescription fontSize={[16, 18, 20, 22, 22]}>
              Vai alla pagina dei <Link fontWeight="bold" color="perla.700" onClick={() => navigate("/video")}><Text as='u'>video</Text></Link> per scoprire le nostre perle!
            </AlertDescription>
          </VStack>
        </Alert>

        <Lottie 
            animationData={SuccessLottie} 
            style={{
                height: "320px",
            }}
        />;
    </VStack>
  )
}