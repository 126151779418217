
import React from 'react';
import { Box, HStack, Icon, Text, Tag, Button, Stack, useToast } from '@chakra-ui/react';
import { StrapiOrderResponse, StrapiVideoResponse, VideoEnabled } from '../ExternalTypes';
import ReactPlayer from 'react-player';
import useAuth from '../Hooks/useAuth';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import qs from 'qs';
import axios from 'axios';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import { MdDone } from 'react-icons/md';

type VideoBoxProps = {
    categoryName: string;
    video: StrapiVideoResponse;
    userOrder: StrapiOrderResponse;
    totalVideosEnabled: number;
  }

export default function VideoBox({
    categoryName,
    video,
    userOrder,
    totalVideosEnabled
}: VideoBoxProps) {

    const queryClient = useQueryClient();
    const toast = useToast();
    const { isAuthenticated, user, token } = useAuth();
    const strapiHeaders = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

    const { isLoading: isVideoEnabledLoading, data: videoEnabled } = useQuery(
        ["videos-enabled", user.id, video.id], 
        async () => {
          const query = qs.stringify({
            sort: ['createdAt:asc'],
            filters: {
              User: {
                id: {
                  $eq: user.id,
                }
              },
              Video:
              {
                id: {
                    $eq: video.id
                }
              }
            },
            populate: '*',
            pagination: {
              pageSize: 1,
              page: 1,
            },
          }, {
            encodeValuesOnly: true, // prettify URL
          });

          return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/videos-enabled?${query}`, 
          {
            headers: strapiHeaders
          })
          .then((response) => {
            return response.data.data[0] ? response.data.data[0] as VideoEnabled : null;
          });
      });

    const { mutateAsync: enableVideo, isLoading: isVideoEnabling } = useMutation(
    async (values: any) => {
        console.log(values);
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/videos-enabled`, 
        {
            data: values
        }, 
        {
            headers: strapiHeaders
        });
    },
    {
        onSuccess: (data, variables) => {
          queryClient.invalidateQueries(["videos-enabled", user.id, video.id], {
            refetchActive: true,
          });
          queryClient.invalidateQueries(["videos-enabled", user.id], {
            refetchActive: true,
          });
          
          toast({
            title: 'VIDEO',
            description: `Attivazione video effettuata con successo!`,
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        },
        onError: (error, variables) => {
          queryClient.invalidateQueries(["videos-enabled", user.id, video.id], {
            refetchActive: true,
          });
          queryClient.invalidateQueries(["videos-enabled", user.id], {
            refetchActive: true,
          });
  
          toast({
            title: 'VIDEO',
            description: `Attivazione video fallita!`,
            status: 'error',
            duration: 6000,
            isClosable: true,
          })
        }
      }
    );

    return (
        <Box
            borderWidth={1}
            // borderColor={videoEnabled || (video.attributes.Tags.search("free") != -1) 
            //   ? "perla.500" 
            //   : "perla.800"
            // }
        >
            <HStack
                py={2}
                bgColor={(videoEnabled && videoEnabled != null) || 
                  (video.attributes.Tags.search("free") != -1)
                    ? "perla.500" 
                    : "perla.800"
                }
                justifyContent="center"
            >
              <Text 
                  textAlign="center"
                  color="white"
                  fontWeight="bold"
                  fontSize={[14, 16, 18, 22, 24]}
              >
                  {video.attributes.Name.toUpperCase()}
              </Text>
            </HStack>

            <HStack py={2} px={3} w="100%">
                {video.attributes.Tags.search("free") != -1 
                    ?
                    <>
                        <Icon as={FaLockOpen}
                          color="perla.500"
                          w="26px"
                          h="26px"
                          maxW="max-content"
                        />
                        <Tag 
                          colorScheme="green"
                          maxW="max-content"
                          minW="fit-content"
                        >
                          FREE
                        </Tag>
                    </>
                    :                 
                    <Icon 
                      as={(videoEnabled && videoEnabled != null)  
                        ? FaLockOpen 
                        : FaLock
                      }
                      color={(videoEnabled && videoEnabled != null) ? "perla.500" : "gray"}
                      w="26px"
                      h="26px"
                      maxW="max-content"
                    />
                }
                <Tag 
                    bgColor="perla.700"
                    color="perla.100"
                    maxW="max-content"
                    minW="fit-content"
                    py={2} px={3}
                >
                    <Text mb={0.3}>{categoryName}</Text>
                </Tag>

                <Stack w="100%" alignItems="self-end">
                    {
                      (!videoEnabled || videoEnabled == null) && 
                      (video.attributes.Tags.search("free") == -1) &&
                      ((userOrder && totalVideosEnabled < userOrder.attributes.MaxVideos) || user.role.name === "Administrator")
                          ?
                              <Button 
                                  bgColor="perla.500" 
                                  color="white"
                                  size="md"
                                  onClick={() => {
                                    enableVideo({
                                      User: user.id,
                                      Video: video.id
                                    })}
                                  }
                                  isDisabled={isVideoEnabling}
                                  _hover={{
                                    bgColor: "orange.500"
                                  }}
                              >
                                  <Icon as={MdDone} w="24px" h="24px" mr={2}/><Text>Attiva</Text>
                              </Button>
                          : null
                    }
                </Stack>
            </HStack>
        
            <Box 
                className='player-wrapper'
                alignItems="center"
                backgroundImage={video.attributes.VimeoPictureBaseLink}
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="contain"
            >
                {(videoEnabled && videoEnabled != null) || (video.attributes.Tags.search("free") != -1) ?
                    <ReactPlayer 
                        url={video.attributes.Link} 
                        controls 
                        loop={false}
                        muted={true}
                        light={video.attributes.VimeoPictureBaseLink}
                        className='react-player'
                        width='100%'
                        height='100%'
                    />
                : null}
            </Box>
      </Box>
    )
}