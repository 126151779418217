import { Box, Stack } from '@chakra-ui/react';
import React from 'react';
import ReactPlayer from 'react-player';
import ChiSiamoSlider from './ChiSiamoSlider';

export default function ChiSiamoPage() {
  return (
    <Stack pt={6} pb={20}>

     <Stack bgColor="white">
        <Box alignItems="center">
          <ReactPlayer 
            url={`${process.env.REACT_APP_BACKEND_URL}/uploads/PERLALIS_PRESENTAZIONI_UFFICIALE_a86baf1807.mp4?updated_at=2022-11-01T23:26:16.889Z`} 
            controls={true} 
            width='100%'
            height='720px'
            playing={true}
            loop={true}
          />
        </Box>
      </Stack>

      <Stack px={[4, 4, 6, 8, 10]} pt={10}>
        <ChiSiamoSlider/>
      </Stack>  

    </Stack>
  );
}