import {
  Flex,
  Stack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Tag,
  Text,
  Image
} from "@chakra-ui/react";
import Logo from '../Logo.webp';
import { useLocation } from "react-router-dom";

export function NoMatchPage() {
  const location = useLocation();

  return (
    <Flex direction="column" h="100vh" alignItems="center">
      <Stack alignItems="center" flexGrow={1} minWidth={500} mt={10}>
        <Alert
          status="warning"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          py={10}
          px={20}
        >
          <AlertIcon boxSize="60px" mr={0} />
          <AlertTitle mt={4} mb={4} fontSize="2xl">
            <Text>Pagina non trovata!</Text>
          </AlertTitle>
          <AlertDescription maxWidth="full">
            <Text>
              Mi dispiace, il percorso{" "}
              <Tag colorScheme="gray" fontSize="lg">
                {location.pathname}
              </Tag>{" "}
              a cui stai provando ad accedere non esiste.
            </Text>
          </AlertDescription>
        </Alert>
      </Stack>
    </Flex>
  );
}
