import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Box,
} from "@chakra-ui/react";
import { UserForm, UserValues } from "../../../Components/UserForm";

type UserAddModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (values: UserValues) => void;
  isMutateLoading: boolean;
};

export function UserAddModal({
  isOpen,
  onClose,
  onCreate,
  isMutateLoading
}: UserAddModalProps) {

  return !isOpen ? null : (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size="xl" 
      trapFocus={true}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent 
        minWidth="fit-content" 
        bgColor="perla.600"
      >
        <ModalCloseButton 
          px={2} 
          mt={1}
          isDisabled={isMutateLoading} 
          bgColor="white"
          color="perla.500"
          fontSize={16}
          _hover={{
            bgColor: "perla.800",
            color: "perla.400"
          }}
        />
        <ModalBody mt={12} mb={6} mx={2}>
          <Box 
            alignSelf="center"
            borderWidth={2}
            borderRadius={10}
            boxShadow="md"
            bgColor="white"
          >
            <UserForm
              onSubmit={onCreate}
              title="Crea nuovo utente"
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
